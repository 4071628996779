body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*{

  outline: none;
}

.tasks-select {
  width: 130px;
}
.tasks-select .MuiOutlinedInput-notchedOutline{
  border: none;
}

button[aria-label="Close Tour"]{
  margin-top: -10px;
}